import { Component, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';


@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        top: '0',
      })),
      state('closed', style({
        top: '-200px',
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),

  ]
})

export class NavigationComponent implements OnInit {
  isOpen = false;
 
  constructor() { }

  ngOnInit() {
  }

  openClose() {
      this.isOpen = !this.isOpen;
  }

}
