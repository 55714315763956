import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TeachersComponent } from './components/teachers/teachers.component';
import { PlanComponent } from './components/plan/plan.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { LinksComponent } from './components/links/links.component';
import { MediaComponent } from './components/media/media.component';
import { RODOComponent } from './components/rodo/rodo.component';
import { CooperationComponent } from './components/cooperation/cooperation.component';
import { PlaceComponent } from './components/place/place.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    TeachersComponent,
    PlanComponent,
    RegistrationComponent,
    LinksComponent,
    MediaComponent,
    RODOComponent,
    CooperationComponent,
    PlaceComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
